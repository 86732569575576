import * as React from 'react'
import "./video.scss"

const Video = (props) => {

    return (
        <iframe src={props.src}
                frameBorder="0"
                width={props.width}
                height={props.height}
                allow="fullscreen"
                allowFullScreen
                style={{maxWidth: props.width, maxHeight: props.height}}
                className="video-embed"
                title="video"
        >
        </iframe>
    )
}

export default Video