import React, { useEffect } from "react"
import "./modal.scss"

const Modal = (props) => {

    useEffect(() => {
        let modalId = props.btnId + "-modal"
        let modal = document.getElementById(modalId)
        let btn = document.getElementById(props.btnId)

        btn.onclick = function() {
            modal.style.display = "block"
        }

        window.onkeydown = function(event) {
            if (event.keyCode === 27) {
                document.querySelectorAll("[id$=-modal").forEach(modal => modal.style.display = "none")
            }
        }
    })

    const close = (event) => {
        event.target.parentElement.style.display = "none"
    }

    return (
        <div id={`${props.btnId}-modal`} className="modal" tabIndex={-1}>
            <span className="close" onClick={close} role="button">&times;</span>
            {props.children}
        </div>
    )
}

export default Modal