import * as React from 'react'
import "./section-3.scss"

const Section3 = (props) => {

    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ]

    const getDate = (date) => {
        let d = new Date(date)
        return months[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear()
    }

    return (
        <div className="book-details">
            <h2>Book Details</h2>
            <p>Publication Date: {getDate(props.data.publishedDate)}</p>
            <p>Pages: {props.data.paperbackPages}</p>
        </div>
    )
}

export default Section3