import * as React from 'react'
import Image from "../global/image"
import Modal from "../global/modal";
import parse from "html-react-parser"
import defaultFrontCover from "../../images/coming-soon-front-cover.png"
import defaultBackCover from "../../images/coming-soon-back-cover.png"
import "./section-1.scss"
import Affiliate from "../global/affiliate";

const Section1 = (props) => {
    const getBookFrontCover = (node) => {
        if (node.bookFrontCover.length > 0) {
            return node.bookFrontCover[0].url
        } else {
            return defaultFrontCover
        }
    }

    const getBookBackCover = (node) => {
        if (node.bookBackCover.length > 0) {
            return node.bookBackCover[0].url
        } else {
            return defaultBackCover
        }
    }

    const getBookBackCoverAltText = (node) => {
        if (node.length > 0) {
            return node[0].altText
        } else {
            return "Back Cover Image Coming Soon"
        }
    }

    const getBookFrontCoverAltText = (node) => {
        if (node.length > 0) {
            return node[0].altText
        } else {
            return "Front Cover Image Coming Soon"
        }
    }

    const getBreadcrumbs = () => {
        if (props.data.bookCategory.length > 0) {
            return (
                <>
                    <li><a href="/books">Books</a></li>
                    {getBookCategories(props.data.bookCategory)}
                </>
            )
        } else {
            return (
                <li><a href="/books">Books</a></li>
            )
        }
    }

    const getBookCategories = (node) => {
        if (node.length > 0) {
            return node.map((category, i) => {
                return <li><a href={`/books/${category.slug}`} key={i}>{category.title}</a></li>
            })
        } else {
            return null
        }
    }

    const getBookCoauthors = () => {
        if (props.data.bookCoauthors.length > 0) {
            return props.data.bookCoauthors.map((author, i) => {
                return <li><a href={`/${author.uri}`} key={i}>{author.title}</a></li>
            })
        } else {
            return null
        }
    }

    const getBookTitle = () => {
        if (props.data.bookTitlePrepend) {
            return <h4>{props.data.bookTitlePrepend}<br />{props.data.title}</h4>

        } else {
            return <h4>{props.data.title}</h4>
        }
    }

    return (
        <div className="book-detail-section-1">
            <div className="content-column-row">
                <div className="book-detail-top-left">
                    <div className="breadcrumb">
                        <ul>
                            {getBreadcrumbs(props.data.bookCategory)}
                        </ul>
                    </div>
                    <div className="book-detail-top-post-row">
                        <div className="book-detail-top-post">
                            <div className="cover">
                                <Image src={getBookFrontCover(props.data)} alt={getBookFrontCoverAltText(props.data.bookFrontCover)} />
                                <div className="detail-top-post-overlay">
                                    <a id="enlarge-front"><span>Enlarge</span></a>
                                </div>
                                <Modal btnId="enlarge-front">
                                    <Image src={getBookFrontCover(props.data)} alt={getBookFrontCoverAltText(props.data.bookFrontCover)} />
                                </Modal>
                            </div>
                            <div className="download-link-col">
                                <a id="enlarge-front-mobile"><span>Enlarge</span></a>
                                <Modal btnId="enlarge-front-mobile">
                                    <Image src={getBookFrontCover(props.data)} alt={getBookBackCoverAltText(props.data.bookFrontCover)} />
                                </Modal>
                                <a className="download-link" href={getBookFrontCover(props.data)} target="_blank" rel="noreferrer">Download Front Cover</a>
                            </div>
                        </div>
                        <div className="book-detail-top-post">
                            <div className="cover">
                                <Image src={getBookBackCover(props.data)} alt={getBookBackCoverAltText(props.data.bookBackCover)} />
                                <div className="detail-top-post-overlay">
                                    <a id="enlarge-back"><span>Enlarge</span></a>
                                </div>
                                <Modal btnId="enlarge-back">
                                    <Image src={getBookBackCover(props.data)} alt={getBookBackCoverAltText(props.data.bookBackCover)} />
                                </Modal>
                            </div>
                            <div className="download-link-col">
                                <a id="enlarge-back-mobile"><span>Enlarge</span></a>
                                <Modal btnId="enlarge-back-mobile">
                                    <Image src={getBookBackCover(props.data)} alt={getBookBackCoverAltText(props.data.bookBackCover)} />
                                </Modal>
                                <a className="download-link" href={getBookBackCover(props.data)} target="_blank" rel="noreferrer">Download Back Cover</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="book-detail-top-right">
                    <div className="inspiration-row-stories-blog">
                        <div className="breadcrumb">
                            <ul>
                                {getBreadcrumbs(props.data.bookCategory)}
                            </ul>
                        </div>

                        {getBookTitle()}

                        {
                            // Check if the book has a subtitle
                            (props.data.bookSubtitle)
                            ? <span className="subtitle">{parse(props.data.bookSubtitle)}</span>
                            : null
                        }

                        <ul className="coauthors">
                            {getBookCoauthors()}
                        </ul>

                        <Affiliate book={props.data}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Section1