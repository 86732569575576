import * as React from 'react'
import ImageLink from '../global/imageLink'
import loadable from '@loadable/component'
import defaultFrontCover from "../../images/coming-soon-front-cover.png"
import '../owl-carousel/scss/owl.carousel.scss'
import "./section-5.scss"

const OwlCarousel = loadable(() => import('react-owl-carousel'), { ssr: false });

const Section5 = (props) => {
    const getBookFrontCover = (node) => {
        if (node.bookFrontCover.length > 0) {
            return node.bookFrontCover[0].url
        } else {
            return defaultFrontCover
        }
    }

    const getBookFrontCoverAltText = (node) => {
        if (node.bookFrontCover.length > 0) {
            return node.bookFrontCover[0].altText
        } else {
            return "Front Cover Image Coming Soon"
        }
    }

    const getSlides = () => {
        return props.data.map(node => (
            <div className="recommended-post">
                <div className="recommended-post-img">
                    <ImageLink src={getBookFrontCover(node)} alt={getBookFrontCoverAltText(node)} to={"/" + node.uri}></ImageLink>
                </div>
                <h4><a href={"/" + node.uri}>{node.bookTitlePrepend} {node.title}</a></h4>
            </div>
        ))
    }

    const carouselResponse = {
        0:{
            items:2
        },
        600:{
            items:3
        },
        1000:{
            items:5
        }
    }

    if (props.data.length > 0) {
        return (
            <div className="recommended-collection">
                <div className="container-small">
                    <h2>You Might Also Like</h2>
                    <div className="recommended-slider">
                        <OwlCarousel loop={false} autoplay={false} responsive={carouselResponse} dot={false} nav={true} margin={16}>
                            { getSlides() }
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        )
    } else {
        return null
    }

}

export default Section5