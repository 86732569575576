import * as React from "react"
import {graphql} from "gatsby";
import InnerLayout from "../../components/global/innerLayout"
import Podcast from "../../components/global/podcast"
import Section1 from "../../components/book/section-1"
import Section2 from "../../components/book/section-2"
import Section3 from "../../components/book/section-3"
import Section4 from "../../components/book/section-4"
import Section5 from "../../components/book/section-5"

export const query = graphql`
    query ($id: [Craft_QueryArgument], $entryId: Craft_QueryArgument, $categories: [Craft_QueryArgument]) {
      craft {
        seo: entry(section: "bookPage", id: $id) {
            seomatic {
              metaTitleContainer
              metaTagContainer
              metaLinkContainer
              metaScriptContainer
              metaJsonLdContainer
              metaSiteVarsContainer
              frontendTemplateContainer
            }
        }
        book: entry(section: "bookPage", id: $id) {
          ... on Craft_bookPage_default_Entry {
            title
            bookTitlePrepend
            bookSubtitle
            affiliateEnabledAmazon
            affiliateEnabledAmazonCa
            affiliateEnabledBookshopOrg
            affiliateEnabledIndiebound
            affiliateEnabledIndigo
            affiliateLinkIndigo
            affiliateEnabledBarnesNoble
            affiliateLinkBarnesNoble
            isbn10
            isbn13
            bookFrontCover {
			    key:id,
                url(transform:"bookCoverLarge")
                ... on Craft_websiteBookPage_Asset {
                    altText
                }
            }
            bookBackCover {
				key:id,
                url(transform:"bookCoverLarge")
                ... on Craft_websiteBookPage_Asset {
                    altText
                }
            }
            bookCategory {
                key: id
                slug
                title
            }
            bookCoauthors {
			  ... on Craft_biographyPage_default_Entry {
				id
                title
                uri
              }
            }
            bookFullDescription
            bookPressRelease {
                ... on Craft_bookPressRelease_bookPressRelease_BlockType {
                    pressReleaseEntry {
                    uri
                }
                buttonText
              }
            }
            publishedDate
            paperbackPages
            video {
              ... on Craft_video_video_BlockType {
                key: id
                videoType
                embedUrl
                caption
              }
            }
          }
        }
        relatedBooks: entries(section: "bookPage", limit: 10, relatedToCategories: {id: $categories}, id: ["not", $entryId], orderBy: "publishedDate desc, title") {
          ... on Craft_bookPage_default_Entry {
            key:id
            uri
            title
            bookTitlePrepend
            bookFrontCover {
                url(transform:"bookCoverLarge")
                ... on Craft_websiteBookPage_Asset {
                    altText
                }
            }
          }
        }
      }
    }
`


const Books = ({ data, pageContext }) => {
    return (
        <InnerLayout seomatic={data.craft.seo.seomatic} entryId={pageContext.id} forceNewsletterSignupEnabled={true}>
            <Section1 data={data.craft.book} />
            <Section2 data={data.craft.book} />
            <Section3 data={data.craft.book} />
            <Section4 data={data.craft.book} />
            <Section5 data={data.craft.relatedBooks} />
            <Podcast />
        </InnerLayout>
    )
}

export default Books