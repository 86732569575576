import * as React from 'react'
import Button from "../global/button"
import parse from "html-react-parser";
import "./section-2.scss"

const Section2 = (props) => {
    const getPressReleaseButtonText = (pressRelease) => {
        if (pressRelease.buttonText === null) {
            return (
                <>View Press Release</>
            )
        } else {
            return (
                <>{pressRelease.buttonText}</>
            )
        }
    }

    const getPressRelease = () => {
        if (props.data.bookPressRelease.length > 0) {
            return props.data.bookPressRelease.map((pressRelease) => {
                if (pressRelease.pressReleaseEntry.length > 0) {
                    return <Button classes="btn-primary press-release-button text-center" to={`/${pressRelease.pressReleaseEntry[0].uri}`} openInNewTab={true} text={getPressReleaseButtonText(pressRelease)} />
                }
                return null
            })
        }
    }

    if (props.data.bookFullDescription) {
        return (
            <div className="about-book">
                <div className="container">
                    <h2>About This Book</h2>
                    {parse(props.data.bookFullDescription)}
                    {getPressRelease()}
                </div>
            </div>
        )
    } else {
        return null
    }
}

export default Section2