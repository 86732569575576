import * as React from 'react'
import Video from "../global/video";
import "./section-4.scss"

const Section4 = (props) => {

    const getVideos = (videos) => {
        return videos.map(video => (
            <div className="video-detail-post">
                <Video src={video.embedUrl} width={398} height={228}/>
                <h3>
                    <a href={video.embedUrl} target="_blank" rel="noreferrer">{video.caption}</a>
                </h3>
            </div>
        ))
    }

    if (props.data.video.length > 0) {
        return (
            <div className="book-detail-video">
                <div className="container">
                    <h2>Video</h2>
                    <div className="book-detail-video-inner">
                        <div className="video-detail-row">
                            {getVideos(props.data.video)}
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return null
    }

}

export default Section4